import React, { useState } from "react";
import { Button, Grid, Link } from "@mui/material";
import "./App.css";
import VID from "./art.mp4";
import goldGIF from "./gold.gif";
import diamondGIF from "./diamond.gif";
import silverGIF from "./silver.gif";
const mintPrice = "0.18";

const PassTypes = () => {
  function getQuestionAnswer(question, ans) {
    return (
      <Grid
        item
        xs={"auto"}
        sx={{
          textAlign: "center",
          width: "100vw",
          marginBottom: "60px",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{
            textAlign: "center",
            width: "100vw",
          }}
        >
          <h2 className="SubTitle">{question}</h2>
          <h2 className="SubSubTitle">{ans}</h2>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ padding: "30px 0 0 0" }}
    >
      <Grid
        justifyContent="center"
        alignItems="center"
        sx={{ padding: "10px" }}
      >
        <h1 className="Title">FAQs</h1>
      </Grid>
      <Grid container spacing={0} justifyContent="center" alignItems="center">
        {/*}
        {getQuestionAnswer(
          "Is each color a different type of pass?",
          "Yes, each color is a different tier pass that will give you free access, priority entry, and additional entries. For example, silver is the most common and will give you free entry and a +1. Diamond is the rarest but gives you a +2. There are a limited number of nasty passes, and they will be first come, first serve."
        )}
        */}
        {getQuestionAnswer(
          "What platform will you be offering NastyPasses on?",
          "NastyPasses can be minted from nastypass.xyz on May 24th. After mint, NastyPasses will be available immediately on OpenSea, LooksRare, and Rariable. Other platforms will come later."
        )}

        {getQuestionAnswer("How much?", `Mint price will be ${mintPrice} ETH`)}

        {getQuestionAnswer(
          "How many passes can I mint?",
          "You can mint up to 3 NastyPacks. Each pack gives you a random chance to get one of 3 NastyPass tiers."
        )}

        {getQuestionAnswer(
          "How do I mint?",
          `To mint a NastyPass, you'll need at least ${mintPrice} ETH in your wallet + gas fee. MetaMask is the recommended wallet. MetaMask is a free extension you can download from the google chrome store and attach to your chrome and brave browser.`
        )}

        {getQuestionAnswer(
          "How many NastyPasses are available?",
          `There are 160 NastyPasses total. 100 Silver, 50 Gold, and 10 Diamond available for public mint.`
        )}
      </Grid>
    </Grid>
  );
};

export default PassTypes;
