import "./App.css";
import React, { useState } from "react";
import { Button, Grid, Link } from "@mui/material";
import MintCounter from "./MintCounter";
import Media from "./Media";
import SocialFabs from "./SocialFabs";
import { ethers, BigNumber } from "ethers";
import nstyABI from "./nsty.json";
const nstyPassAddress = "0x575D9A2eA50cb2e33fd2C52AE87e6259E55FB11B";
const mintPrice = 0.18;
const contractLink = `https://etherscan.io/address/${nstyPassAddress}`;

const MainMint = ({
  accounts,
  setAccounts,
  isMobile,
  setHashLink,
  setShowAlert,
  setShowAlertError,
}) => {
  const isConnected = Boolean(accounts[0]);
  const [mintCount, setMintCount] = useState(1);

  function getTransactionLink(hash) {
    return `https://rinkeby.etherscan.io/tx/${hash}`;
  }

  async function connectAccount() {
    if (window.ethereum) {
      const _accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      setAccounts(_accounts);
    }
  }

  async function mint() {
    if (window.ethereum) {
      console.log("handleMint");
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        nstyPassAddress,
        nstyABI.abi,
        signer
      );

      try {
        let { hash } = await contract.mint(BigNumber.from(mintCount), {
          value: ethers.utils.parseEther((mintPrice * mintCount).toString()),
        });

        let hashLink = getTransactionLink(hash);
        setHashLink(hashLink);
        setShowAlert(true);
      } catch (err) {
        //console.log("error: ", err.message);
        //alert(err.message);
        setShowAlertError(true);
      }
    }
  }

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{
        textAlign: "center",
        padding: 0,
      }}
    >
      <h1 className="Title">NASTY PASS</h1>
      <h2 className="SubTitle">
        No more covers. No more waits. <br />
        For life…
      </h2>
      {isMobile() ? (
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{ width: "30%" }}
        >
          <Media />
        </Grid>
      ) : null}

      <MintCounter mintCount={mintCount} setMintCount={setMintCount} />
      <Button
        color="secondary"
        onClick={(e) => {
          isConnected ? mint() : connectAccount();
        }}
        variant="contained"
        style={{ fontSize: "20px", backgroundColor: "#764AF1" }}
      >
        {isConnected ? "Mint Now" : "Connect Wallet"}
      </Button>
      <div className="ContractAddressHeading">Contract Address</div>
      <div className="ContractAddress">
        <Link sx={{ color: "white" }} href={contractLink}>
          {nstyPassAddress}
        </Link>
      </div>
      <SocialFabs />
    </Grid>
  );
};

export default MainMint;
