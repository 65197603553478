import React from "react";
import { Grid } from "@mui/material";
import "./App.css";
//import VID from "./art.mp4";
import goldGIF from "./gold.gif";
import diamondGIF from "./diamond.gif";
import silverGIF from "./silver.gif";
import AmethystGIF from "./honorable.gif";
const PassTypes = () => {
  /*
  function isMobile() {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
  */
  function getTierComponent(tierType, size, description, gif) {
    return (
      <Grid
        item
        xs={size}
        sx={{
          paddingRight: "50px",
          paddingLeft: "50px",
          textAlign: "center",
          width: "100vw",
          padding: "30px",
        }}
      >
        <img
          src={gif}
          alt={description}
          style={{ width: "30vw", alignSelf: "center", height: "auto" }}
        />
        <h2 className="SubTitle">{tierType} Tier</h2>
        <h2 className="SubSubTitle">{description}</h2>
      </Grid>
    );
  }
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ paddingBottom: "20px", paddingTop: "40px" }}
    >
      <Grid
        justifyContent="center"
        alignItems="center"
        sx={{ padding: "10px" }}
      >
        <h1 className="Title">Pass Types</h1>
      </Grid>
      <Grid container spacing={0} justifyContent="center" alignItems="center">
        {getTierComponent(
          "Amethyst",
          "auto",
          "The most exclusive pass, granting free entry to all Mike Nasty Productions +3, priority entry, exclusive music access, access to exclusive merch, and secret rewards. These passes are only issued to selected individuals or purchased on secondary markets.",
          AmethystGIF
        )}
        {getTierComponent(
          "Diamond",
          "auto",
          "Free Entry to all Mike Nasty Productions +2, Priority Entry, Exclusive access to free music drops, merchandise, private events and more.",
          diamondGIF
        )}
        {getTierComponent(
          "Gold",
          "auto",
          "Free Entry to all Mike Nasty Productions +1, Priority Entry, Exclusive access to free music drops, merchandise, private events and more.",
          goldGIF
        )}
        {getTierComponent(
          "Silver",
          "auto",
          "Free Entry to all Mike Nasty Productions +1, Exclusive access to free music drops, merchandise, private events and more.",
          silverGIF
        )}
      </Grid>
    </Grid>
  );
};

export default PassTypes;
