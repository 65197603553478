import React from "react";
import { Grid, Fab } from "@mui/material";
import { Instagram, Twitter } from "@mui/icons-material";
import opensea from "./assets/opensea.png";
const twitterLink = "https://twitter.com/nastypassnft";
const igLink = "https://www.instagram.com/nastypassnft/";
const openSeaLink = "https://opensea.io/collection/nastypass-honorables";
const SocialFabs = () => {
  return (
    <Grid
      container
      sx={{ marginTop: "15px" }}
      direction="row"
      justifyContent="center"
      alignItems="center"
    >
      <Fab
        style={{ backgroundColor: "#764AF1", marginRight: "15px" }}
        size="small"
        onClick={(e) => {
          window.open(openSeaLink);
        }}
      >
        <img src={opensea} alt="opensea" style={{ width: "100%" }} />
      </Fab>

      <Fab
        style={{ backgroundColor: "#764AF1", marginRight: "15px" }}
        size="small"
        onClick={(e) => {
          window.open(twitterLink);
        }}
      >
        <Twitter sx={{ color: "white" }} />
      </Fab>

      <Fab
        style={{ backgroundColor: "#764AF1" }}
        size="small"
        onClick={(e) => {
          window.open(igLink);
        }}
      >
        <Instagram sx={{ color: "white" }} />
      </Fab>
    </Grid>
  );
};

export default SocialFabs;
