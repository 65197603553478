import React from "react";
import { Grid, Fab } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";

const MintCounter = ({ mintCount, setMintCount }) => {
  return (
    <Grid
      container
      direction="row"
      style={{ marginTop: "10px", marginBottom: "20px", width: "200px" }}
      justifyContent="space-between"
      alignItems="center"
    >
      <Remove
        style={{ color: "white" }}
        onClick={(e) => {
          if (mintCount > 1) setMintCount(mintCount - 1);
        }}
      />

      <Fab
        color="secondary"
        aria-label="mintCount"
        style={{ backgroundColor: "#764AF1" }}
      >
        {mintCount}
      </Fab>
      <Add
        style={{ color: "white" }}
        onClick={(e) => {
          if (mintCount < 3) setMintCount(mintCount + 1);
        }}
      />
    </Grid>
  );
};

export default MintCounter;
